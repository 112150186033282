import ArtistSongHead from './ArtistSongHead';
import ListenInApp from 'components/ListenInApp';
import Lyrics from './Lyrics';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import SimilarArtistAside from '../SimilarArtistAside';
import SongPlayer from 'views/Artist/Song/SongPlayer';
import { getArtistUrl } from 'utils/url';

type Props = {
  artistId: number;
  artistName: string;
};

function Song({ artistId, artistName }: Props) {
  const similar = (
    <SimilarArtistAside playedFrom={PLAYED_FROM.PROF_SONG_SIMILAR} />
  );

  return artistId ? (
    <>
      <ArtistSongHead />
      <ListenInApp seedId={artistId} seedType="artist" />
      <PageBody
        backLink={getArtistUrl(artistId, artistName) as string}
        dataTest={ViewName.ArtistSong}
        more={similar}
        title={artistName}
      >
        <SongPlayer />
        <Lyrics />
      </PageBody>
    </>
  ) : null;
}

export default Song;
