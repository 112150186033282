import Featuring from 'shared/ui/components/Featuring';
import HeaderPlayer from './primitives/HeaderPlayer';
import MediaServerImage from 'components/MediaServerImage';
import PlayButton from './primitives/PlayButton';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import ProfileArtWrapper from './primitives/ProfileArtWrapper';
import ProfileDetails from './primitives/ProfileDetails';
import ProfileInfo from './primitives/ProfileInfo';
import ProfileName from './primitives/ProfileName';
import ProfilePlayer from './primitives/ProfilePlayer';
import SongPageExplicit from './primitives/SongPageExplicit';
import StationDescription from './primitives/StationDescription';
import StationInfo from './primitives/StationInfo';
import StationMeta from './primitives/StationMeta';
import StationName from './primitives/StationName';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import { formatSongDuration } from 'utils/time/dates';
import { STATION_TYPE } from 'constants/stationTypes';

type Props = {
  albumName: string;
  artistId: number;
  artistName: string;
  customRadioEnabled: boolean;
  duration: number;
  explicit: boolean;
  imageUrl: string;
  name: string;
  onDemandEnabled: boolean;
  trackId: number;
};

function SongPlayer({
  albumName,
  artistId,
  artistName,
  customRadioEnabled,
  duration,
  explicit,
  imageUrl,
  name,
  onDemandEnabled,
  trackId,
}: Props) {
  let stationId;
  let stationType;

  // If OD is disabled, we play artist radio instead
  if (onDemandEnabled) {
    stationId = trackId;
    stationType = STATION_TYPE.TRACK;
  } else {
    stationId = artistId;
    stationType = STATION_TYPE.ARTIST;
  }

  return (
    <HeaderPlayer>
      <ProfileInfo>
        <ProfileName data-test="profile-name" itemProp="name">
          {name}
        </ProfileName>
        {explicit ? <SongPageExplicit /> : null}
        <ProfileDetails>
          {`${albumName}`},{formatSongDuration(duration)}
        </ProfileDetails>
        <ProfilePlayer>
          <ProfileArtWrapper>
            <MediaServerImage
              alt={name}
              aspectRatio={1}
              ops={[fit(125, 125)]}
              src={imageUrl}
            />
          </ProfileArtWrapper>
          <StationInfo>
            {customRadioEnabled ? (
              <PlayButton
                artistId={artistId}
                artistName={artistName}
                playedFrom={PLAYED_FROM.PROF_SONG_PLAY}
                stationId={stationId}
                stationType={stationType}
                trackId={trackId}
                trackName={name}
              />
            ) : null}
            <StationMeta>
              <StationName>{name}</StationName>
              <StationDescription className="tight">
                <div>
                  <Featuring
                    artistId={artistId}
                    key={`featuring${artistId}`}
                    truncate={60}
                  />
                </div>
              </StationDescription>
            </StationMeta>
          </StationInfo>
        </ProfilePlayer>
      </ProfileInfo>
    </HeaderPlayer>
  );
}

export default SongPlayer;
